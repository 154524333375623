import * as React from "react";

import { cn } from "@lib/utils";
import { cva } from "class-variance-authority";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const input = cva([
  "bg-input",
  "border-input",
  "border",
  "dark:text-gray-100",
  "disabled:bg-input-disabled",
  "disabled:cursor-not-allowed",
  "disabled:opacity-80",
  "file:bg-white",
  "file:border-0",
  "file:font-medium",
  "file:text-sm",
  "flex",
  "focus:ring-none",
  "focus:outline-offset-2",
  "focus:outline",
  "focus:invalid:outline-red-500",
  "h-10",
  "placeholder:text-muted-foreground",
  "px-3",
  "py-2",
  "rounded-md",
  "text-black",
  "text-sm",
  "w-full",
]);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          input(),
          className,
          "focus:border-none focus:outline-current focus:ring-transparent"
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
