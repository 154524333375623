import { localeValidator } from "common";
import { usePathname } from "next/navigation";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { Globe } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/AlertDialog";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { cn } from "@lib/utils";

export const LanguageSelector = ({ className }: { className?: string }) => {
  const t = useTranslations("Ticketing.components.Footer");
  let pathname = usePathname();
  const { locale, query } = useRouter();
  if (pathname === "/ticketing/reception")
    pathname += `?token=${typeof query.token == "string" ? query.token : ""}`;
  return (
    <AlertDialog>
      <AlertDialogTrigger className={cn("flex items-center gap-2", className)}>
        <Globe size={16} />
        {t(localeValidator.parse(locale))}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogPrimitive.Cancel className="absolute right-4 top-2 text-primary-foreground">
          x
        </AlertDialogPrimitive.Cancel>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("selectLanguage")}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogAction
          asChild
          className={"bg-transparent text-card-foreground hover:bg-primary"}
        >
          <Link replace href={pathname} locale={"pt"}>
            {t("pt")}
          </Link>
        </AlertDialogAction>

        <AlertDialogAction
          asChild
          className={"bg-transparent text-card-foreground hover:bg-primary"}
        >
          <Link replace href={pathname} locale={"en"}>
            {t("en")}
          </Link>
        </AlertDialogAction>
      </AlertDialogContent>
    </AlertDialog>
  );
};
