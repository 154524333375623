import { cn } from "@lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import { type ButtonHTMLAttributes, type DetailedHTMLProps } from "react";

const button = cva(
  [
    "inline-block",
    "rounded-full",
    "border-2",
    "text-center",
    "font-semibold",
    "focus:outline-offset-2",
    "focus:outline",
    "focus:invalid:outline-red-500",
    "disabled:opacity-50",
    "disabled:cursor-not-allowed",
  ],
  {
    variants: {
      variant: {
        filled:
          "bg-accent text-white enabled:hover:bg-accent-dark border-accent",
        inverse:
          "text-accent border-accent enabled:hover:bg-gray-100 dark:enabled:hover:bg-gray-100/10",
        danger:
          "text-white dark:bg-red-800 bg-red-600 border-red-600 enabled:hover:bg-red-600/90  dark:enabled:hover:bg-red-800/80",
        disabled:
          "bg-jacarta-200 dark:border-jacarta-200 text-white dark:bg-jacarta-500 cursor-not-allowed dark:opacity-50 opacity-70 border-jacarta-400 ",
      },
      width: {
        fullWidth: "w-full",
        minWidth: "w-min whitespace-nowrap",
        grow: "grow",
      },
      padding: {
        normal: "py-3 px-8",
        small: "py-1 px-4",
      },
    },
    defaultVariants: {
      variant: "filled",
      width: "minWidth",
      padding: "normal",
    },
  }
);

type ButtonProps = VariantProps<typeof button> &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button: React.FC<React.PropsWithoutRef<ButtonProps>> = (props) => {
  const { variant, width, padding, className, ...rest } = props;

  return (
    <button
      {...rest}
      className={cn(button({ variant, width, padding }), className)}
    />
  );
};
